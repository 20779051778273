<template>
  <router-view />
</template>

<script>

import router from '@/router';
import axios from '@/api/axios'; // 引入封装好的api

const validUrl = '/sso/valid';


export default {
  name: 'App',

  mounted() {
    this.timer = setInterval(async () => {
      try {
        const validResponse = await axios.post(validUrl);
        if (validResponse.status !== 200){
          await router.push('/login')
        }
      } catch (error) {
         await router.push('/login')
      }
    }, 60000);
  },

  unmounted() {
    clearInterval(this.timer)
  },
  components: {
  }
}

// app.vue写在script里面  main.js写在app挂在完之后
const debounce = (fn, delay) => {
  let timer
  return (...args) => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
  constructor(callback) {
    callback = debounce(callback, 200);
    super(callback);
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
