// router/index.js
import GitlabAuth from "@/components/GitlabAuth.vue";
import { createRouter, createWebHistory } from 'vue-router';
import AuthCallback from '../components/AuthCallback.vue';
import MdView from "@/views/MdView.vue";
import MdEdit from "@/views/MdEdit.vue";
const routes = [
    {
        name: "views3",
        path: "/views/:path(.*)",
        component: MdView
    },
    {
        name: "edit3",
        path: "/edit/:path(.*)",
        component: MdEdit
    },
    {
        name: "home",
        path: '/',
        component: MdView
    },
    {
        name: "login",
        path: '/login',
        component: GitlabAuth
    },
    {
        name: '404',
        path: '/:catchAll(.*)',
        component: () => import(`../views/404.vue`)
    },
    {
        path: '/sso/callback',
        name: 'AuthCallback',
        component: AuthCallback,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;