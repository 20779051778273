<template>
  <div>
    <button @click="loginWithGitLab">Login with GitLab</button>
  </div>
</template>

<script>

export default {

  methods:{
    loginWithGitLab() {
      const clientId = 'ee93605e9c4698c8a98923731461135cd6655fba1dd4cc8987e9cff131843eec';
      const redirectUri = 'https://wiki.wsh-study.com/sso/callback';
      const scope = 'openid profile email read_user';

      const authUrl = `https://gitlab.wsh-study.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}`;

      window.location.href = authUrl;
    }
  },
};
</script>