
<script >
import axios from '@/api/axios'; // 引入封装好的api
import router from '@/router';
export default {

  async mounted() {


    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');
    if (code) {

      const tokenUrl = '/sso/token';

      try {
        const tokenResponse = await axios.post(tokenUrl,  {code: code} );

        const token = tokenResponse.data.token;
        const refreshToken = tokenResponse.data.refreshToken
        localStorage.setItem("token",token)
        localStorage.setItem("refreshToken",refreshToken)
        await router.push('/')

      } catch (error) {
        console.error('Authentication failed:', error);
        await router.push('/login')
      }
    } else {
        console.error('No authorization code provided');
        await router.push('/login')
    }
  },

  unmounted() {

  }
}

</script>

<style scoped>

</style>