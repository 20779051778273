// api.js
import router from '@/router';
import axios from 'axios';

// 创建axios实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // API的base_url
    timeout: 60000 // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
    config => {
        // 在这里可以做一些请求前的操作，比如设置token
        const token = localStorage.getItem("token");
        if (token) {
            config.headers['Token'] = 'Bearer ' + token;
        }

        const refreshToken = localStorage.getItem("refreshToken");
        if (refreshToken) {
            config.headers['Refresh-Token'] = refreshToken;
        }
        return config;
    },
    error => {
        // 请求错误处理
        console.error('Request Error:', error);
        return Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    response => {
        if (response.status === 401) {
            router.push("/login")
        } else if (response.status !== 200) {
            return Promise.reject(new Error(response || 'Error'));
        } else {
            const newRefreshToken = response.headers["refresh-token"]
            const newToken = response.headers["token"]
            const token = localStorage.getItem("token")

            if (newToken) {
                if (token && token !== newToken) {
                    // 获取当前时间
                    const now = new Date();
                    // 将当前时间格式化为字符串
                    const currentTime = now.toLocaleString();
                    // 打印当前时间
                    console.log(currentTime + "更新token")
                    localStorage.setItem("token", newToken)
                    localStorage.setItem("refreshToken", newRefreshToken)
                }
            }
            return response;
        }
    },
    error => {
        if (error.response && error.response.status === 401) {
            router.push("/login")
            return
        }
        // 响应错误处理
        console.error('Response Error:', error);
        return Promise.reject(error);
    }
);

export default service;